var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.profileMatter && _vm.matter)?[_c('h2',[_vm._v("Reporte de Assessment")]),_c('Header',{staticClass:"pt-1 pb-4",attrs:{"Matter":_vm.profileMatter,"section_id":_vm.section_id,"allows_crud":false}})]:_vm._e(),[_c('b-tabs',{model:{value:(_vm.tab_index),callback:function ($$v) {_vm.tab_index=$$v},expression:"tab_index"}},[_c('b-tab',{attrs:{"title":`Por ${_vm.$getVisibleNames(
          'mesh.egressprofilematter',
          false,
          'Asignatura'
        )}`}},[(_vm.chartdata)?_c('DashboardAssessmentMatter',{key:_vm.component_key,attrs:{"chartdata":_vm.chartdata,"sheet_name":_vm.sheet_name,"table_header":_vm.table_header,"profile_matter":_vm.profileMatter,"section_id":_vm.section_id}}):_vm._e()],1),_c('b-tab',{attrs:{"title":"Por Unidad"}},[_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"selector-container py-2 my-2 mx-0"},[_c('b-form-group',{staticClass:"ra-group-selector px-4 m-0",attrs:{"label":`${_vm.$getVisibleNames(
                'teaching.ramicro',
                false,
                'RA Micro'
              )}:`,"label-size":"sm","label-cols":"0","label-cols-sm":"1","label-cols-md":"2","label-cols-lg":"3","label-cols-xl":"4"}},[_c('b-form-select',{attrs:{"options":_vm.study_units,"value-field":"id","text-field":"name","size":"sm"},on:{"change":_vm.fetchRAMicroPerSection},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("-- Seleccione "+_vm._s(_vm.$getVisibleNames("teaching.ramicro", false, "RA Micro"))+" --")])]},proxy:true}]),model:{value:(_vm.selected_ra_micro),callback:function ($$v) {_vm.selected_ra_micro=$$v},expression:"selected_ra_micro"}})],1)],1)]),(_vm.chartdata)?_c('DashboardAssessmentMatter',{key:_vm.component_key,attrs:{"chartdata":_vm.chartdata,"sheet_name":_vm.sheet_name,"table_header":_vm.table_header,"profile_matter":_vm.profileMatter,"section_id":_vm.section_id}}):_vm._e()],1)],1)],(_vm.user.is_professor)?_c('DashboardCustomFab',{staticClass:"noprint",attrs:{"section_id":_vm.section_id,"show_assessment_report":false}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }