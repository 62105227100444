<template>
  <div>
    <template v-if="profileMatter && matter">
      <h2>Reporte de Assessment</h2>
      <Header
        class="pt-1 pb-4"
        :Matter="profileMatter"
        :section_id="section_id"
        :allows_crud="false"
      ></Header>
    </template>
    <template>
      <b-tabs v-model="tab_index">
        <b-tab
          :title="`Por ${$getVisibleNames(
            'mesh.egressprofilematter',
            false,
            'Asignatura'
          )}`"
        >
          <DashboardAssessmentMatter
            v-if="chartdata"
            :key="component_key"
            :chartdata="chartdata"
            :sheet_name="sheet_name"
            :table_header="table_header"
            :profile_matter="profileMatter"
            :section_id="section_id"
          ></DashboardAssessmentMatter>
        </b-tab>
        <b-tab title="Por Unidad">
          <div class="text-center">
            <div class="selector-container py-2 my-2 mx-0">
              <b-form-group
                :label="`${$getVisibleNames(
                  'teaching.ramicro',
                  false,
                  'RA Micro'
                )}:`"
                label-size="sm"
                label-cols="0"
                label-cols-sm="1"
                label-cols-md="2"
                label-cols-lg="3"
                label-cols-xl="4"
                class="ra-group-selector px-4 m-0"
              >
                <b-form-select
                  :options="study_units"
                  v-model="selected_ra_micro"
                  value-field="id"
                  text-field="name"
                  size="sm"
                  @change="fetchRAMicroPerSection"
                  ><template v-slot:first>
                    <b-form-select-option :value="null"
                      >-- Seleccione
                      {{
                        $getVisibleNames("teaching.ramicro", false, "RA Micro")
                      }}
                      --</b-form-select-option
                    >
                  </template></b-form-select
                >
              </b-form-group>
            </div>
          </div>
          <DashboardAssessmentMatter
            v-if="chartdata"
            :key="component_key"
            :chartdata="chartdata"
            :sheet_name="sheet_name"
            :table_header="table_header"
            :profile_matter="profileMatter"
            :section_id="section_id"
          ></DashboardAssessmentMatter>
        </b-tab>
      </b-tabs>
    </template>
    <DashboardCustomFab
      v-if="user.is_professor"
      class="noprint"
      :section_id="section_id"
      :show_assessment_report="false"
    ></DashboardCustomFab>
  </div>
</template>
<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
// import { toast } from "@/utils/utils";
export default {
  name: "AssessmentReportView",
  components: {
    Header: () => import("@/components/teaching/Planification/Header"),
    DashboardCustomFab: () =>
      import("@/components/dashboard/DashboardCustomFab"),
    // Table: () => import("@/components/reports/Table"),
    DashboardAssessmentMatter: () => import("./DashboardAssessmentMatter"),
  },
  data() {
    return {
      section_id: Number(this.$route.params.section_id)
        ? Number(this.$route.params.section_id)
        : null,
      list: [],
      selected_student_id: null,
      keyComponent: 0,
      window_top: 0,
      checked: false,
      component_key: 0,
      selected_ra_micro: null,
      worksheet: "",
      table_header: "",
      sheet_name: "",
      study_units: [],
      chartdata: null,
      tab_index: 0,
    };
  },
  computed: {
    ...mapGetters({
      sections: names.SECTIONS,
      egress_profiles_matters: names.EGRESS_PROFILES_MATTERS,
      matters: names.MATTERS,
      studyUnits: names.STUDY_UNITS,
      careers: names.CAREERS,
      institution: "getInstitution",
      user: "getUser",
    }),
    getSection() {
      if (!this.section_id) return [];
      return this.sections.find((x) => x.id == this.section_id);
    },
    profileMatter() {
      if (!this.getSection) return null;
      return this.egress_profiles_matters.find(
        (x) => x.id == this.getSection.egress_profile_matter
      );
    },
    matter() {
      if (!this.profileMatter) return null;
      return this.matters.find((x) => x.id == this.profileMatter.matter);
    },
  },
  methods: {
    slotStudentSelected(student_id) {
      this.selected_student_id = student_id;
      this.keyComponent += 1;
    },
    onScroll(e) {
      this.window_top = e.target.documentElement.scrollTop;
    },
    fetchRAMicroPerSection() {
      this.loading = true;
      if (this.selected_ra_micro && this.section_id) {
        this.component_key += 1;
        this.$restful
          .Get(
            `/teaching/ra_micro_and_ec_macro_per_section/?section=${this.section_id}&study_unit=${this.selected_ra_micro}`
          )
          .then((response) => {
            this.chartdata = response;
            this.table_header = "REPORTE DE ASSESSMENT POR UNIDAD";
            this.sheet_name = "ASSESSMENT (UNIDADES)";
            this.loading = false;
          });
      }
    },
    fetchRAMacroPerSection() {
      this.loading = true;
      this.$restful
        .Get(`/teaching/ra_macro_per_section/?section=${this.section_id}`)
        .then((response) => {
          this.chartdata = response;
          this.sheet_name =
            "ASSESSMENT (" +
            this.$getVisibleNames(
              "mesh.egressprofilematter",
              false,
              "Asignatura"
            ).toUpperCase() +
            ")";
          this.table_header =
            "REPORTE DE ASSESSMENT POR " +
            this.$getVisibleNames(
              "mesh.egressprofilematter",
              false,
              "Asignatura"
            ).toUpperCase();
          this.collapse_visible = false;
        });
    },
    loadKey() {
      this.component_key += 1;
    },
    getProgramName(egress_profile_matter_id) {
      let egress_profile_matter = this.egress_profiles_matters.find(
        (x) => x.id == egress_profile_matter_id
      );
      if (egress_profile_matter) {
        let career = this.careers.find(
          (element) => element.id == egress_profile_matter.career
        );
        if (career) {
          return career.name;
        }
      }
    },
  },
  watch: {
    tab_index() {
      this.component_key += 1;
      if (this.tab_index == 0) {
        this.fetchRAMacroPerSection();
      } else {
        this.fetchRAMicroPerSection();
      }
    },
  },
  created() {
    this.$hasPermissions();
    this.$store.dispatch(names.FETCH_CAREERS);
    if (this.section_id) {
      this.$store.dispatch(names.FETCH_SECTION, this.section_id).then((sec) => {
        if (sec) {
          this.$store
            .dispatch(
              names.FETCH_EGRESS_PROFILE_MATTER,
              sec.egress_profile_matter
            )
            .then((egress_profile_matter) => {
              if (egress_profile_matter) {
                this.$store
                  .dispatch(
                    names.FETCH_STUDY_UNITS,
                    egress_profile_matter.matter
                  )
                  .then((response) => {
                    this.study_units = response;
                  });
              }
            });
        }
      });
    }
    this.$store.dispatch(names.FETCH_TAXONOMIES);
    this.$store
      .dispatch(names.FETCH_SECTION, this.section_id)
      .then((section) => {
        this.$store
          .dispatch(
            names.FETCH_EGRESS_PROFILE_MATTER,
            section.egress_profile_matter
          )
          .then((matter) => {
            this.$store.dispatch(names.FETCH_MATTER, matter.matter);
          });
      });
    window.addEventListener("scroll", this.onScroll);
  },
  mounted() {
    setTimeout(() => {
      this.fetchRAMacroPerSection();
    }, 500);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
};
</script>
<style scoped>
.student-carousel {
  display: flex;
  transform: translateY(46%);
}
.student-label {
  margin-left: 1%;
  transform: translateY(-20%);
}
.student-carousel-sticky {
  position: fixed !important;
  top: 30px;
  width: 100%;
  min-height: 1vh;
  padding-top: 4em;
  z-index: 300;
  transform: translateX(-12px);
  background: var(--primary-color);
}
.selector-container {
  background: rgb(230, 230, 230);
  padding: 0.3em 3em;
  border-radius: 3px;
  transition: max-height 0.15s ease-out;
}
.toggle-active {
  max-height: 500px;
  transition: max-height 0.25s ease-in;
}
.check-type {
  background: #007bff !important;
  margin-inline: 0.7em;
  height: 1.12em;
  width: 2.3em !important;
  /* margin-top: 0.2%; */
  border-radius: 10px;
}
.icon-check {
  color: white;
  transform: translate(-71%, -41%);
  transition: 0.2s;
}
.icon-check-active {
  transform: translate(25%, -41%) !important;
}
/* .ra-group-selector {
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 200ms, visibility 200ms;
  transition: opacity 200ms, visibility 200ms;
}
.visible {
  visibility: visible;
  opacity: 1;
} */
</style>